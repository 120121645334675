import {React, useEffect} from "react";
import "./styles/about.css"; // Import your CSS file for styling
import blankImage from "./images/dp.png"; // Import a blank image for the team section
import { Helmet } from "react-helmet"; // Import Helmet from the react-helmet library

function About() {
     // Scroll to the top of the page when the component mounts (or loads) after the redirect
     useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  return (
    <div className="about-container">
      <Helmet>
        <title>
          RepairTechies: Welcome to RepairTechies, your one-stop destination for
          top-quality repair services in India. We specialize in repairing
          mobile phones, laptops, tablets, and more, offering fast and reliable
          solutions to get your valuable devices back in working order.
          E-scooter repair services in Delhi.
        </title>
        <meta
          name="description"
          content="Mobile phone repair service, Smartphone screen repair, scooter repair near me ,i Phone repair near me, 
          Android phone repair, Mobile battery replacement, Cell phone water damage repair, 
          Mobile repair shop, Cracked phone screen repair, Mobile device fix, 
          Broken phone repair, Laptop repair services, Laptop screen replacement, 
          Laptop motherboard repair, MacBook repair center, Laptop keyboard repair, 
          Laptop overheating fix, PC laptop repair shop, Laptop hardware repair, 
          Dell laptop repair near me, Laptop data recovery, Tablet screen repair, 
          iPad repair service, Android tablet fix, Tablet battery replacement, 
          Tablet charging port repair, Samsung tablet repair, Broken tablet screen fix, 
          Tablet water damage repair, Tablet technician near me, Tablet software troubleshooting, 
          Electric scooter repair service, E-scooter maintenance, Electric scooter battery replacement, 
          E-scooter brake repair, Scooter tire replacement, Electric scooter motor repair, 
          E-scooter charging issues, Electric scooter diagnostic check, Scooter repair shop, 
          E-scooter technician, Scooter repair near me, Electric scooter repair near me, 
          Scooter repair shop near me, Scooter repair shops, Electric scooter repair shop near me, 
          Scooter repair home service near me, Scooter tire repair near me, How to repair scooter tire, 
          How to repair electric scooter."
        />
      </Helmet>
      {/* About Us Section */}
      <section className="about-us">
        <h1 className="section-title">About Us</h1>
        <p className="section-description">
          Welcome to our repair service, where we are dedicated to providing
          top-notch solutions for your device problems. Learn more about our
          mission and commitment to quality service.
        </p>
      </section>

      {/* Our Team Section */}
      <section className="our-team">
        <h2 className="section-title">Our Team</h2>
        <div className="team-members">
          {/* Individual Team Member */}
          <div className="team-member">
            <img
              src={blankImage} // Replace with a blank image or avatar
              alt="Team Member 1"
              className="team-member-image"
            />
            <h3 className="team-member-name">Rohit Rajput</h3>
            <p className="team-member-role">Founder & CEO</p>
            <p className="team-member-description">
              Rohit is a founder of our repair service, driven by a passion for
              excellence in device repair and customer satisfaction. He serves
              as our CEO, leading the way towards delivering top-quality
              service.
            </p>
          </div>
          {/* Add more team members as needed */}
          <div className="team-member">
            <img
              src={blankImage} // Replace with a blank image or avatar
              alt="Team Member 1"
              className="team-member-image"
            />
            <h3 className="team-member-name">Vishal Rajput</h3>
            <p className="team-member-role">Co-Founder & CTO</p>
            <p className="team-member-description">
              Vishal is a co-founder of our repair service, driven by a passion
              for excellence in device repair and customer satisfaction. He is
              an experienced Software Development Engineer (SDE) with a
              dedication to delivering top-notch solutions.
            </p>
          </div>
          <div className="team-member">
            <img
              src={blankImage} // Replace with a blank image or avatar
              alt="Team Member 1"
              className="team-member-image"
            />
            <h3 className="team-member-name">Ankit Kumar</h3>
            <p className="team-member-role">Co-Founder & CFO</p>
            <p className="team-member-description">
              Ankit Kumar, an alumnus of IIT Kanpur, is a co-founder of our
              repair service. He is driven by a passion for excellence in device
              repair and customer satisfaction.
            </p>
          </div>
          <div className="team-member">
            <img
              src={blankImage} // Replace with a blank image or avatar
              alt="Team Member 1"
              className="team-member-image"
            />
            <h3 className="team-member-name">Dolly Raj</h3>
            <p className="team-member-role">HR expert</p>
            <p className="team-member-description">
              Dolly Raj, our HR expert, is dedicated to hiring top talent to
              join our team. Dolly is a co-founder of our repair service, driven
              by a passion for excellence in device repair and customer
              satisfaction.
            </p>
          </div>
        </div>
      </section>

      {/* Our Values Section */}
      <section className="our-values">
        <h2 className="section-title">Our Values</h2>
        <ul className="values-list">
          <li className="value-item">
            <h3 className="value-title">Quality Repairs</h3>
            <p className="value-description">
              We are committed to providing high-quality repairs to ensure your
              devices work like new.
            </p>
          </li>
          <li className="value-item">
            <h3 className="value-title">Customer Satisfaction</h3>
            <p className="value-description">
              Your satisfaction is our priority, and we go the extra mile to
              meet your needs.
            </p>
          </li>
          <li className="value-item">
            <h3 className="value-title">Reliable Service</h3>
            <p className="value-description">
              Count on us for reliable and efficient device repair solutions.
            </p>
          </li>
          <li className="value-item">
            <h3 className="value-title">Affordable Pricing</h3>
            <p className="value-description">
              We offer competitive pricing without compromising on the quality
              of our services.
            </p>
          </li>
          {/* Add more values as needed */}
        </ul>

        {/* Why We Trust You? */}
        <h2 className="section-title">Why We Trust You?</h2>
        <ul className="trust-values">
          <li>Transparency in pricing and services</li>
          <li>Integrity in every repair job</li>
          <li>Timely communication and updates</li>
          <li>Skilled and certified technicians</li>
          <li>Use of genuine, high-quality parts</li>
        </ul>
        <p className="section-description">
          At our repair service, trust is at the core of what we do. We trust
          you because we believe in transparency, integrity, and a commitment to
          delivering exceptional service. Your satisfaction and trust in our
          quality repairs drive us to excel in every aspect of our work. We are
          dedicated to earning and maintaining your trust, one device repair at
          a time.
        </p>
      </section>

      {/* Contact Us Section */}
      <section className="contact-us">
        <h2 className="section-title">Contact Us</h2>
        <p className="section-description">
          If you have questions or need assistance, please feel free to reach
          out to us at <a href="mailto:contact.repairtechies@gmail.com">contact.repairtechies@gmail.com</a>.
        </p>
      </section>
    </div>
  );
}

export default About;
