import React from "react";
import { Link } from "react-router-dom";
import "./styles/footer.css";
import whatsappIcon from "./images/whatsapplogo.png";
import { Helmet } from "react-helmet"; // Import Helmet from the react-helmet library

function Footer() {
  return (
    <footer>
      <div>
      <Helmet>
        <title>
          RepairTechies: Welcome to RepairTechies, your one-stop destination for
          top-quality repair services in India. We specialize in repairing
          mobile phones, laptops, tablets, and more, offering fast and reliable
          solutions to get your valuable devices back in working order.
          E-scooter repair services in Delhi.
        </title>
        <meta
          name="description"
          content="Mobile phone repair service, Smartphone screen repair, scooter repair near me ,i Phone repair near me, 
          Android phone repair, Mobile battery replacement, Cell phone water damage repair, 
          Mobile repair shop, Cracked phone screen repair, Mobile device fix, 
          Broken phone repair, Laptop repair services, Laptop screen replacement, 
          Laptop motherboard repair, MacBook repair center, Laptop keyboard repair, 
          Laptop overheating fix, PC laptop repair shop, Laptop hardware repair, 
          Dell laptop repair near me, Laptop data recovery, Tablet screen repair, 
          iPad repair service, Android tablet fix, Tablet battery replacement, 
          Tablet charging port repair, Samsung tablet repair, Broken tablet screen fix, 
          Tablet water damage repair, Tablet technician near me, Tablet software troubleshooting, 
          Electric scooter repair service, E-scooter maintenance, Electric scooter battery replacement, 
          E-scooter brake repair, Scooter tire replacement, Electric scooter motor repair, 
          E-scooter charging issues, Electric scooter diagnostic check, Scooter repair shop, 
          E-scooter technician, Scooter repair near me, Electric scooter repair near me, 
          Scooter repair shop near me, Scooter repair shops, Electric scooter repair shop near me, 
          Scooter repair home service near me, Scooter tire repair near me, How to repair scooter tire, 
          How to repair electric scooter."
        />
      </Helmet>

        <h1>RepairTechies</h1>
        {/* <Link to="/"><h1>Carpentry</h1></Link> */}
        <p>
          RepairTechies offers a wide range of repair services to meet your
          needs. We specialize in mobile phone repair services, including
          smartphone screen repair, iPhone repair (available near you), and
          Android phone repair. We also provide mobile battery replacement and
          cell phone water damage repair services. Our mobile repair shop is
          known for its expertise in cracked phone screen repair, mobile device
          fixes, and general phone repairs.
          <Link to={"/MobileRepair"}>(Repair)</Link>
        </p>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/About">About Us</Link>
          </li>
          <li>
            <Link to="/Services">Services</Link>
          </li>
          <li>
            <Link to="/Contact">Contact Us</Link>
          </li>
          <li>
            <Link to="https://furniturefarrukhabad.com/">Furniture</Link>
          </li>
        </ul>
      </div>
      <div>
        <h2>Contact Information</h2>
        <p>
          Address: 209625, <Link to={"/Farrukhabad"}>Farrukhabad</Link>,
          <Link to={"https://en.wikipedia.org/wiki/Kanpur"}>Kanpur</Link>,
          <Link to={"https://en.wikipedia.org/wiki/India"}>India</Link>
          <br />
          <p style={{ fontSize: "medium", fontWeight: "bold" }}>
            Whatsapp No:{" "}
            <img
              src={whatsappIcon}
              alt="WhatsApp Icon"
              style={{
                verticalAlign: "middle",
                marginRight: "8px",
                height: "20px",
              }}
            />
            {""}
            8542832268
          </p>
          Phone: 8542832268
          <br />
          Email: contact.repairtechies@gmail.com
        </p>
      </div>
      <div>
        <h2>Follow Us</h2>
        <ul className="social-links">
          <li>
            <a
              href="https://www.facebook.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </a>
          </li>
          <li>
            <a
              href="https://www.twitter.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Twitter
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              LinkedIn
            </a>
          </li>
        </ul>
      </div>
      <div>
        <p>
          &copy; {new Date().getFullYear()} RepairTechies. All rights reserved.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
