import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet"; // Import Helmet from the react-helmet library

import "../components/styles/header.css";
function header() {
  return (
    <div className="div1">
     <Helmet>
        <title>
          E-scooter repair services in Delhi.
        </title>
        <meta
          name="description"
          content="Scooter repair near me, Electric scooter repair near me, 
          Scooter repair shop near me, Scooter repair shops, Electric scooter repair shop near me, 
          Scooter repair home service near me, Scooter tire repair near me, How to repair scooter tire, 
          How to repair electric scooter."
        />
      </Helmet>

      <div class="header">
        <ul>
          <li style={{ fontSize: "1rem", fontWeight: "bold" }}>
            <Link to={"/"}>RepairTechies</Link>
          </li>

          {/* <li>
            <Link to={"/"}>Home</Link>
          </li> */}
          <li>
            <Link to={"/online-mobile-repair"}>Mobile-Repair</Link>
          </li>
          <li>
            <Link to={"/online-laptop-repair"}>Laptop-Repair</Link>
          </li>
          <li>
            <Link to={"/online-escooter-repair"}>E-Scooter</Link>
          </li>
          <li>
            <Link to={"/online-sell-buy"}>Smart-Watchs</Link>
          </li>
           {/* <li>
            <Link to={"/online-romatic-talk"}>Talk</Link>
          </li> */}
          <li>
            <Link to={"/online-tech-services"}>Services</Link>
          </li>
          
          <li>
            <Link to={"/india-repairtechies-about"}>About</Link>
          </li>
         
          <li>
            <Link to={"/india-repairtechies-contact"}>Contact</Link>
          </li>
          <li>
            <Link to={"/best-tech-blog-news"}>Tech-News</Link>
          </li>
          {/* <li>
            <Link to={"/best-sports-tech-blog-news"}>Blog</Link>
          </li> */}
          {/* <li>
            <Link to={"/latest-tech-blog-news"}>BlogPage</Link>
          </li> */}
          
        </ul>
      </div>
    </div>
  );
}

export default header;
