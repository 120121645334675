import {React, useEffect} from "react";
import "./styles/LaptopRepair.css"; // Import your CSS file for styling
import whatsappIcon from "./images/whatsapplogo.jpg";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet"; // Import Helmet from the react-helmet library

function LaptopRepair() {
     // Scroll to the top of the page when the component mounts (or loads) after the redirect
     useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  return (
    <div className="laptop-repair-container">
      <Helmet>
        <title>
          RepairTechies:Welcome to RepairTechies, your one-stop destination for
          top-quality repair services in India. We specialize in repairing
          mobile phones, laptops, tablets services in India.
        </title>
        <meta
          name="description"
          content="Mobile phone repair service, Smartphone screen repair, iPhone repair near me,
             Android phone repair, Mobile battery replacement, Cell phone water damage repair, 
             Mobile repair shop, Cracked phone screen repair, Mobile device fix, Broken phone repair,
              Laptop repair services, Laptop screen replacement, Laptop motherboard repair,
               MacBook repair center, Laptop keyboard repai."
        />
      </Helmet>
      {/* Hero Section */}
      <section className="hero">
        <div className="hero-content">
          <h1 className="hero-title">Your Trusted Laptop Repair Experts</h1>
          <p className="hero-description">
            Get your laptop back in working order with our top-notch repair
            services. Our skilled technicians are here to resolve your laptop
            issues.
          </p>
          <button className="hero-button">Learn More</button>
        </div>
      </section>

      {/* Contact Section */}
      <div className="contact-container">
        <section className="contact">
          <h1>Contact Us</h1>
          <p>
            Mobile: <a href="tel:85428322268">8542832268</a>
          </p>
          <p>
            Whatsapp No:{" "}
            <img
              src={whatsappIcon}
              alt="WhatsApp Icon"
              style={{
                verticalAlign: "middle",
                marginRight: "8px",
                height: "20px",
              }}
            />{" "}
            <a href="https://wa.me/8542832268">8542832268</a>
          </p>
          <p className="contact-email">
            You can Mail us:{" "}
            <a href="mailto:contact.repairtechies@gmail.com">contact.repairtechies@gmail.com</a>
          </p>
        </section>
      </div>

      {/* Services Section */}
      <section className="services">
        <div className="service">
          <div className="service-icon">
            <i className="fas fa-tools"></i>
          </div>
          <h2 className="service-title">Expert Technicians</h2>
          <p className="service-description">
            Our expert technicians are trained to handle various laptop issues.
          </p>
        </div>

        <div className="service">
          <div className="service-icon">
            <i className="fas fa-clock"></i>
          </div>
          <h2 className="service-title">Quick Turnaround</h2>
          <p className="service-description">
            We provide quick and efficient repair services to minimize your
            downtime.
          </p>
        </div>

        <div className="service">
          <div className="service-icon">
            <i className="fas fa-shield-alt"></i>
          </div>
          <h2 className="service-title">Quality Repairs</h2>
          <p className="service-description">
            We use high-quality parts to ensure the durability of our laptop
            repairs.
          </p>
        </div>

        <div className="service">
          <div className="service-icon">
            <i className="fas fa-microchip"></i>
          </div>
          <h2 className="service-title">Hardware Upgrades</h2>
          <p className="service-description">
            Improve your laptop's performance with hardware upgrades like RAM
            and SSD installation.
          </p>
        </div>

        <div className="service">
          <div className="service-icon">
            <i className="fas fa-virus"></i>
          </div>
          <h2 className="service-title">Virus Removal</h2>
          <p className="service-description">
            Is your laptop infected? We offer virus removal and security
            services to keep your data safe.
          </p>
        </div>

        <div className="service">
          <div className="service-icon">
            <i className="fas fa-hdd"></i>
          </div>
          <h2 className="service-title">Data Recovery</h2>
          <p className="service-description">
            Accidentally lost data? We specialize in data recovery from damaged
            hard drives and storage media.
          </p>
        </div>

        {/* Add more services as needed */}
      </section>

      {/* Call to Action */}
      <section className="cta">
        <h2 className="cta-title">Ready to Get Your Laptop Repaired?</h2>
        <p className="cta-description">
          Contact us today to schedule your laptop repair service.
        </p>
        <Link to="/Contact" className="cta-button">
          Contact Us
        </Link>
      </section>
    </div>
  );
}

export default LaptopRepair;
