import React from "react";
import { Link } from "react-router-dom"; // Import Link for routing
import "./styles/page404.css";
import { Helmet } from "react-helmet";

function Page404() {
  return (
    <main className="page-not-found">
      <Helmet>
        <title>E-scooter repair services in Delhi.</title>
        <meta
          name="description"
          content=" Scooter repair shops, Electric scooter repair shop near me, 
          Scooter repair home service near me, Scooter tire repair near me, How to repair scooter tire, 
          How to repair electric scooter."
        />
      </Helmet>
      <div className="card">
        <p className="not-found-text">404</p>
        <h1 className="not-found-title">Page not found</h1>
        <p className="not-found-message">
          Sorry, we couldn’t find the page you’re looking for.
        </p>
        <div className="not-found-button">
          <Link to="/" className="go-home-button">
            Go back home
          </Link>
        </div>
        <p className="contact-support">
          Need help?{" "}
          <a href="/" className="contact-support-link">
            Contact support
          </a>
        </p>
      </div>
    </main>
  );
}

export default Page404;
