import React from 'react';
import { Link } from 'react-router-dom';
import './styles/evehicle.css';
import escooter_repair from './images/repair.png';
import {Helmet} from'react-helmet';
const Evehicle = () => {
  return (
    <div className="evehicle-homepage">
       <Helmet>
        <title>
          RepairTechies: E-scooter repair services in Delhi.
        </title>
        <meta
          name="description"
          content="Electric scooter repair service, E-scooter maintenance, Electric scooter battery replacement, 
          E-scooter brake repair, Scooter tire replacement, Electric scooter motor repair, 
          E-scooter charging issues, Electric scooter diagnostic check, Scooter repair shop, 
          E-scooter technician, Scooter repair near me, Electric scooter repair near me, 
          Scooter repair shop near me, Scooter repair shops, Electric scooter repair shop near me, 
          Scooter repair home service near me, Scooter tire repair near me, How to repair scooter tire, 
          How to repair electric scooter."
        />
      </Helmet>
      <header className="header">
        <h1>Evehicle</h1>
        <h2>Your One-Stop Shop for E-Vehicle Repair</h2>
      </header>

      <main className="main">
        <section className="banner">
          <img src={escooter_repair} alt="E-vehicle repair banner" />
          <div className="banner-content">
            <h3>Book an E-Vehicle Mechanic Now!</h3>
            <p>
              Get expert repairs for your e-scooter or e-vehicle at the comfort
              of your home. Pay a flat service charge of only ₹199!
            </p>
            <Link to="/india-repairtechies-contact" className="btn">
              Book Now
            </Link>
          </div>
        </section>

        <section className="services">
          <h2>Our Services</h2>
          <ul>
            <li>E-scooter repair (all models)</li>
            <li>E-vehicle battery diagnostics and replacements</li>
            <li>Motor and controller repairs</li>
            <li>Brakes and electrical repairs</li>
            <li>Regular maintenance and tune-ups</li>
            <li>scooter repair near me</li>
            <li>electric scooter repair near me</li>
            <li>scooter repair shop near me</li>

          </ul>
        </section>

        <section className="how-it-works">
          <h2>How it Works</h2>
          <ol>
            <li>
              <strong>Book Online:</strong> Select your preferred date and time
              on our website.
            </li>
            <li>
              <strong>Relax at Home:</strong> Our certified mechanic will come
              to your doorstep.
            </li>
            <li>
              <strong>Diagnosis & Repair:</strong> The mechanic will diagnose
              the issue and fix it efficiently.
            </li>
            <li>
              <strong>Pay & Enjoy:</strong> Pay the flat ₹199 service charge and
              enjoy your repaired e-vehicle!
            </li>
          </ol>
        </section>

        <section className="additional-content">
          <h2>Additional Content</h2>
          <p>
            Looking for reliable e-scooter repair services in Delhi? Evehicle is your best choice!
            Our expert mechanics specialize in electric scooter repairs, battery diagnostics, motor repairs,
            and more. With our convenient doorstep service, you can get your e-vehicle fixed without any hassle.
            Book your repair now and enjoy a flat service charge of just ₹199!
          </p>
          <p>
            At Evehicle, we understand the importance of timely and efficient repairs for your e-scooter.
            That's why we offer a range of services including maintenance, tune-ups, and diagnostic checks
            to ensure your e-vehicle performs at its best. With our team of certified mechanics, you can trust
            us to deliver high-quality repairs and excellent customer service every time.
          </p>
        </section>
      </main>

      <footer className="footer">
        <p>&copy; Evehicle {new Date().getFullYear()}</p>
      </footer>
    </div>
  );
};

export default Evehicle;
