import React, { useState, useEffect } from "react";
import "./styles/home.css"; // Import your CSS stylesheet
import m1 from "./images/m1.jpg";
import l1 from "./images/l1.jpg";
import m3 from "./images/m3.jpg";
import acer from "./images/acer.png";
import asus from "./images/asus.png";
import dell from "./images/dell.png";
import hp from "./images/hp.png";
import huv from "./images/huv.jpg";
import intel from "./images/intel.png";
import iphone from "./images/iphone.png";
import lenovo from "./images/lenove.png";
import mi from "./images/mi.png";
import micro from "./images/mico.png";
import moto from "./images/moto.jpg";
import nokia from "./images/nokia.png";
import one from "./images/one.png";
import realme from "./images/realme.png";
import sam from "./images/sam.png";
import vivo from "./images/vivo.png";
import tech from "./images/techguy.jpg";
import a1 from "./images/art1.jpg";
import a2 from "./images/art2.jpg";
import a3 from "./images/art3.jpg";
import sell from "./images/sell.png";
import buy from "./images/buy.jpg";
import store from "./images/store.jpg";
import buyy from "./images/buyy.jpg";

import Slider from "react-slick";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet"; // Import Helmet from the react-helmet library

const Home = () => {
  // Scroll to the top of the page when the component mounts (or loads) after the redirect
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // Define the trending articles data
  const trendingArticles = [
    {
      title: "The Ultimate Guide to DIY Smartphone Repair",
      imageUrl: a1,
      link: "/article1",
    },
    {
      title:
        "The Future of Tech Repair: Sustainability and the Right to Repair Movement",
      imageUrl: a2,
      link: "/article2",
    },
    {
      title:
        "Data Recovery: How to Salvage Your Precious Files After a Tech Disaster",
      imageUrl: a3,
      link: "/article3",
    },
  ];
  const faqs = [
    {
      question: "What happens when I place an order for repairing?",
      answer:
        "Once the order is placed, our support team contacts you to confirm your availability for the service & an executive is assigned for your order. The executive will reach your location at a preferred time & repair your device.",
    },
    {
      question: "How do I pay for my order?",
      answer:
        "Once the repair is completed by our technician, you can pay using the following methods: Cash, Paytm, UPI or Credit/Debit Card.",
    },
    {
      question: "How Will I Get An Invoice For The Service?",
      answer:
        "Once the repair order is completed, you will get an email with an invoice in the attachment. The invoice will have the amount charged for the order and can be used later to claim the warranty.",
    },
    // Add more FAQ items as needed
  ];
  // State to track FAQ expansion
  const [expandedFAQs, setExpandedFAQs] = useState({});

  // Function to toggle FAQ expansion
  const toggleFAQ = (index) => {
    setExpandedFAQs((prevExpandedFAQs) => ({
      ...prevExpandedFAQs,
      [index]: !prevExpandedFAQs[index],
    }));
  };

  // brand names
  const brands = [
    { name: "", imageUrl: mi },
    { name: "", imageUrl: vivo },
    { name: "", imageUrl: iphone },
    { name: "", imageUrl: lenovo },
    { name: "", imageUrl: micro },
    { name: "", imageUrl: sam },
    { name: "", imageUrl: one },
    { name: "", imageUrl: nokia },
    { name: "", imageUrl: moto },
    { name: "", imageUrl: realme },
    { name: "", imageUrl: acer },
    { name: "", imageUrl: asus },
    { name: "", imageUrl: dell },
    { name: "", imageUrl: hp },
    { name: "", imageUrl: huv },
    { name: "", imageUrl: intel },

    // Add more brand entries as needed
  ];

  // Slick slider settings
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4, // Number of visible slides at once
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000, // Auto-advance speed in milliseconds
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <div className="home-container">
      <Helmet>
        <title>
          RepairTechies: Welcome to RepairTechies, your one-stop destination for
          top-quality repair services in India. We specialize in repairing
          mobile phones, laptops, tablets, and more, offering fast and reliable
          solutions to get your valuable devices back in working order.
          E-scooter repair services in Delhi.
        </title>
        <meta
          name="description"
          content="Mobile phone repair service, Smartphone screen repair, scooter repair near me ,i Phone repair near me, 
          Android phone repair, Mobile battery replacement, Cell phone water damage repair, 
          Mobile repair shop, Cracked phone screen repair, Mobile device fix, 
          Broken phone repair, Laptop repair services, Laptop screen replacement, 
          Laptop motherboard repair, MacBook repair center, Laptop keyboard repair, 
          Laptop overheating fix, PC laptop repair shop, Laptop hardware repair, 
          Dell laptop repair near me, Laptop data recovery, Tablet screen repair, 
          iPad repair service, Android tablet fix, Tablet battery replacement, 
          Tablet charging port repair, Samsung tablet repair, Broken tablet screen fix, 
          Tablet water damage repair, Tablet technician near me, Tablet software troubleshooting, 
          Electric scooter repair service, E-scooter maintenance, Electric scooter battery replacement, 
          E-scooter brake repair, Scooter tire replacement, Electric scooter motor repair, 
          E-scooter charging issues, Electric scooter diagnostic check, Scooter repair shop, 
          E-scooter technician, Scooter repair near me, Electric scooter repair near me, 
          Scooter repair shop near me, Scooter repair shops, Electric scooter repair shop near me, 
          Scooter repair home service near me, Scooter tire repair near me, How to repair scooter tire, 
          How to repair electric scooter."
        />
      </Helmet>

      {/* Device Selection with Links */}
      <section className="device-selection-section">
        <div className="device-links">
          <Link to="/online-mobile-repair">
            <img src={m1} alt="Mobile Repair" />
            <p>Mobile Repair</p>
          </Link>
          <Link to="/online-laptop-repair">
            <img src={l1} alt="Laptop Repair" />
            <p>Laptop Repair</p>
          </Link>
          <Link to="/online-mobile-repair">
            <img src={m3} alt="Tablet Repair" />
            <p>Tablet Repair</p>
          </Link>
        </div>
      </section>

      {/* Services another section  */}
      <section className="service-options-section">
        <h2 className="service-heading">Our Services</h2>
        <div className="service-options">
          <div className="service-option">
            <Link to="/online-sell-buy">
              <img src={sell} alt="Sell Phone" />
              <p>Sell Phone/Laptop/Tablet</p>
            </Link>
          </div>
          <div className="service-option">
            <Link to="/online-sell-buy">
              <img src={buy} alt="Buy Phone" />
              <p>Buy Phone/Laptop/Tablet</p>
            </Link>
          </div>
          <div className="service-option">
            <Link to="/online-mobile-repair">
              <img src={m1} alt="Repair Phone" />
              <p>Repair Phone/Laptop/Tablet</p>
            </Link>
          </div>
          <div className="service-option">
            <Link to="/online-sell-buy">
              <img src={buyy} alt="Find New Phone" />
              <p>Find New Phone/Laptop/Tablet</p>
            </Link>
          </div>
          <div className="service-option">
            <Link to="/online-sell-buy">
              <img src={store} alt="Nearby Stores" />
              <p>Nearby Stores</p>
            </Link>
          </div>
        </div>
      </section>

      {/* Brand Names and Images Slider */}
      <section className="brand-names-slider">
        <h2>Featured Brands</h2>
        <Slider {...sliderSettings}>
          {brands.map((brand, index) => (
            <div key={index} className="brand-item">
              <img src={brand.imageUrl} alt={brand.name} />
              <div className="brand-name">{brand.name}</div>
            </div>
          ))}
        </Slider>
      </section>

      {/* Hero Section */}
      <section className="hero-section">
        <img src={tech} alt="Technician at work" />
        <h1>Expert Device Repairs, Wherever You Need Them</h1>
        <p>
          Your trusted partner for device repairs, offering reliability,
          convenience, and expertise.
        </p>
        <Link to="/online-mobile-repair" className="cta-link">
          Mobile Repair
        </Link>
        <Link to="/online-laptop-repair" className="cta-link">
          Laptop Repair
        </Link>
        <Link to="/online-laptop-repair" className="cta-link">
          Teblet Repair
        </Link>
        <Link to="/online-laptop-repair" className="cta-link">
          Desktop Repair
        </Link>
      </section>

      {/* Introduction */}
      <section className="introduction-section">
        <p>
          We have certified technicians who specialize in repairing mobile
          phones, laptops, tablets, and more. Our mission is to provide
          high-quality device repair services with fast turnaround times.
        </p>
      </section>

      {/* Appointment Booking */}
      <section className="appointment-booking-section">
        <p className="cta-text">
          Ready to get your device fixed? Book an appointment now!
        </p>
        <Link to="/online-sell-buy" className="cta-link">
          Book an Appointment
        </Link>
        <p>
          Our easy appointment system allows for in-home repairs. Here's how it
          works:
        </p>
        <ol>
          <li>Choose your device type</li>
          <li>Select your device model</li>
          <li>Pick a convenient time slot</li>
          <li>Our technician will arrive at your location</li>
        </ol>
      </section>
      {/* Frequently Asked Questions */}
      <section className="faq-section">
        <h2>Frequently Asked Questions</h2>
        <ul>
          {faqs.map((faq, index) => (
            <li key={index}>
              <button
                className={`faq-toggle ${
                  expandedFAQs[index] ? "expanded" : ""
                }`}
                onClick={() => toggleFAQ(index)}
              >
                {expandedFAQs[index] ? "−" : "+"}
              </button>
              <strong>{faq.question}</strong>
              {expandedFAQs[index] && <p>{faq.answer}</p>}
            </li>
          ))}
        </ul>
        <button className="load-more-button">Load More FAQs</button>
      </section>
      {/* Trending Articles */}
      <section className="trending-articles-section">
        <h2>Trending Articles</h2>
        <ul>
          {trendingArticles.map((article, index) => (
            <li key={index}>
              <img src={article.imageUrl} alt={article.title} />
              <h3>{article.title}</h3>
              <a href={article.link} className="read-more-link">
                Read More
              </a>
            </li>
          ))}
        </ul>
      </section>
      {/* Trust and Assurance */}
      <section className="trust-assurance-section">
        <div className="certifications">
          <img src={m1} alt="Certification 1" />
          <img src={l1} alt="Certification 2" />
        </div>
        <p>
          We are proud to offer a satisfaction guarantee on all our repair
          services. Your trust is our priority.
        </p>
      </section>

      {/* // RepairTechies Section */}
      <section className="repair-techies-section">
        <h2>Sell & Buy Your Old Mobile Phone On RepairTechies</h2>
        <p>
          Want to <Link to="/online-sell-buy">sell your old phone</Link> online
          and get some quick cash? Look no further than RepairTechies. We assure
          you a hassle-free experience. Forget the troubles of visiting a shop,
          negotiating for the best price, and worrying about your data's safety.
          RepairTechies is your one-stop solution to{" "}
          <Link to="/online-sell-buy">sell your old phone online</Link> - it's
          like a dream come true.
        </p>
        <p>
          With RepairTechies' seamless user experience, you can{" "}
          <Link to="/online-sell-buy">sell your old phones online</Link> with
          just a few clicks. Stay in the comfort of your home and sell your old
          mobile phones at the best possible price - yes, it's 100% guaranteed.
          Introducing RepairTechies, your trusted re-commerce partner.
        </p>
        <p>
          And that's not all! RepairTechies now offers the option to{" "}
          <Link to="/online-sell-buy">buy old mobile phones</Link> as well.
          Buying an old mobile phone is the smart way to upgrade without
          breaking the bank. RepairTechies ensures that old mobile phones are in
          excellent condition, making them feel as good as new when you purchase
          them.
        </p>
        <h3>Why Choose RepairTechies?</h3>
        <p>
          RepairTechies allows you to{" "}
          <Link to="/online-sell-buy">sell old phones</Link>, laptops, and other
          gadgets for instant cash. You can use this money to buy newer and
          better models. We have partnered with top brands, including Apple,
          Samsung, Xiaomi, Oppo, Vivo, Amazon, Nokia, Vijay Sales, Reliance
          Digital, HP, OnePlus, Dell, Realme, and more, for offline and online
          exchange offers.
        </p>
        <p>
          Our algorithmically generated price quotes are the fairest in the
          industry. Just provide some details about your old phone's condition,
          age, model number, and warranty period, and you're good to go. Based
          on this information, RepairTechies will offer you the ideal cash value
          for your old phones, laptops, or gadgets. We even offer an exclusive
          home pickup service for mobile phones. Simply{" "}
          <Link to="/online-sell-buy">book an appointment</Link> at your
          convenience, and our representative will handle the rest. No haggling,
          no leaving your home - the entire process is quick and hassle-free.
        </p>
        <h3>Why Buy an Old Mobile Phone from RepairTechies?</h3>
        <p>
          Buying an old mobile phone from RepairTechies is a smart choice. You
          can save money while getting top-notch refurbished smartphones. Our
          old mobile phones are available at almost half the price of new ones.
          Plus, when you buy from RepairTechies, you get peace of mind. Our
          refurbished mobile phones come with a 6-month warranty and undergo
          thorough inspection and expert restoration to ensure they're like new.
        </p>
        <h3>How Does RepairTechies Work?</h3>
        <p>
          <strong>For Selling:</strong> To{" "}
          <Link to="/online-sell-buy">sell your old mobile phone online</Link>
          through RepairTechies, visit our website or app, select the product
          category (e.g., mobile phones), choose the brand and variant, and
          answer a few questions about the device's condition. We'll generate a
          quote for you, and if you're satisfied with the price, we'll arrange a
          home pickup to deliver the cash to you and collect your old device.
          It's a convenient process that doesn't require you to visit a store.
        </p>
        <p>
          <strong>For Buying:</strong> If you want to{" "}
          <Link to="/online-sell-buy">
            buy an old mobile phone from RepairTechies
          </Link>
          , visit our website or app, click on the "Buy Phone" section, select
          the brand and model, choose the condition (Fair, Good, Superb), and
          the price will be displayed. Provide your address and payment details,
          and we'll deliver the device to your doorstep.
        </p>
        <h3>Mobile Phone Brands Available on RepairTechies</h3>
        <p>
          RepairTechies offers a wide range of mobile phone brands available in
          India, along with popular laptop, tablet, and gaming console brands.
          We have partnered with over 20 mobile phone brands and offer:
        </p>
        <ul>
          <li>Assured sale at the best price</li>
          <li>Hassle-free process</li>
          <li>Free home pickup</li>
          <li>Instant cash payment on pickup</li>
        </ul>
        <p>
          Your privacy is our priority. We take extensive measures to ensure the
          security of your old mobile phone's data. Each device goes through a
          thorough data cleaning process, providing maximum security for our
          customers. You can{" "}
          <Link to="/online-sell-buy">sell your old phone</Link> with confidence
          at RepairTechies.
        </p>
      </section>
    </div>
  );
};

export default Home;
