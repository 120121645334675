import { React, useEffect } from "react";
import "./styles/sellbuy.css";
import whatsappIcon from "./images/whatsapplogo.jpg";
import { Helmet } from "react-helmet"; // Import Helmet from the react-helmet library

const SellBuy = () => {
  // Scroll to the top of the page when the component mounts (or loads) after the redirect
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="sell-buy-section">
      <Helmet>
        <title>
          RepairTechies:Welcome to RepairTechies, your one-stop destination for
          top-quality repair services in India. offering fast and reliable
          solutions to get your valuable devices back in working order Mobile
          repair service in India Laptop repair in Uttar Pradesh Tablet repair
          near me Reliable device repair India Best mobile phone repair shop
          Laptop screen replacement India Tablet screen repair Uttar Pradesh
          Farrukhabad (Hardoi) tech repair Fatehgarh device fix Kanpur gadget
          repair Trusted repair services in India.
        </title>
        <meta
          name="description"
          content="Mobile phone repair service, Smartphone screen repair, iPhone repair near me,
             Android phone repair, Mobile battery replacement, Cell phone water damage repair, 
             Mobile repair shop, Cracked phone screen repair, Mobile device fix, Broken phone repair,
              Laptop repair services, Laptop screen replacement, Laptop motherboard repair,
               MacBook repair center,  E-scooter brake repair, Scooter tire replacement,
                   Electric scooter motor repair, E-scooter charging issues, Electric scooter diagnostic check,
                    Scooter repair shop, E-scooter technician."
        />
      </Helmet>
      <h2>Sell & Buy Your Old Mobile Phone On RepairTechies</h2>
      <p>
        Sell your old phones hassle-free with RepairTechies. Get the best price
        guaranteed from the comfort of your home. Your trusted re-commerce
        partner
      </p>
      <p>
        Upgrade affordably with RepairTechies. Buy certified old mobile phones
        in excellent condition, just like new
      </p>

      {/* Contact Card */}
      <div className="contact-container">
        <section className="contact">
          <h1>Contact Us</h1>
          <p>
            Mobile: <a href="tel:8542832268">8542832268</a>
          </p>
          <p>
            Whatsapp No:{" "}
            <img
              src={whatsappIcon}
              alt="WhatsApp Icon"
              style={{
                verticalAlign: "middle",
                marginRight: "8px",
                height: "20px",
              }}
            />{" "}
            <a href="https://wa.me/8542832268">8542832268</a>
          </p>
          <p className="contact-email">
            You can Mail us:{" "}
            <a href="mailto:contact.repairtechies@gmail.com">
              contact.repairtechies@gmail.com
            </a>
          </p>
        </section>
      </div>
    </div>
  );
};

export default SellBuy;
