import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './styles/hindiblog.css';
// import bg from "./images/back.jpg";
const bg = require("./images/back.jpg");
const HindiBlog = () => {
  const [newsData, setNewsData] = useState([]);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiKey = 'fobQRzjo5XXmzhAuKa2RpW63CNDYjbcHLK-zGcITgbR_wCzO';
        const url = `https://api.currentsapi.services/v1/latest-news?language=en&category=technology&apiKey=${apiKey}`;
        const response = await axios.get(url);

        if (response.status === 200) {
          // Assuming the response has a 'news' property with the array of articles
          const articles = response.data.news.slice(0, 20).map(article => ({
            ...article,
            image: article.image || bg // Replace with your default image path
          }));
          setNewsData(articles);
        } else {
          throw new Error('Failed to fetch data');
        }
      } catch (error) {
        console.error('Error fetching news:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="news-container">
      {newsData.map((article, index) => (
        <div key={index} className="news-item">
          <h1>{article.title}</h1>
          <img src={article.image} alt={article.title} />
          <h2>{article.description}</h2>
          <a href={article.url} target="_blank" rel="noopener noreferrer">
            Read more
          </a>
        </div>
      ))}
    </div>
  );
};

export default HindiBlog;
