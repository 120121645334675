import { React, useEffect } from "react";
import "./styles/services.css"; // Don't forget to import your CSS file
import { Helmet } from "react-helmet"; // Import Helmet from the react-helmet library

function Services() {
  // Scroll to the top of the page when the component mounts (or loads) after the redirect
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="services-container">
      <Helmet>
        <title>
          RepairTechies:Welcome to RepairTechies, your one-stop destination for
          top-quality repair services in India. Tablet screen repair Uttar
          Pradesh Farrukhabad (Hardoi) tech repair Fatehgarh device fix Kanpur
          gadget repair Trusted repair services in India.
        </title>
        <meta
          name="description"
          content="Mobile phone repair service, Smartphone screen repair, iPhone repair near me,
             Android phone repair, Mobile battery replacement, Cell phone water damage repair, 
             Mobile repair shop, Cracked phone screen repair, Mobile device fix, Broken phone repair,
              Laptop repair services, Laptop screen replacement, Laptop motherboard repair,
               MacBook repair center,
                Tablet battery replacement, Tablet charging port repair, Samsung tablet repair,
                 Broken tablet screen fix, Tablet water damage repair, Tablet technician near me,
                  Tablet software troubleshooting, Electric scooter repair service, E-scooter maintenance, 
                  Electric scooter battery replacement, E-scooter brake repair, Scooter tire replacement,
                   Electric scooter motor repair, E-scooter charging issues, Electric scooter diagnostic check,
                    Scooter repair shop, E-scooter technician."
        />
      </Helmet>
      <h1 className="services-title">Our Doorstep Repair Services</h1>
      <p className="services-description">
        We offer a wide range of repair services for your convenience. Our
        skilled technicians are just a call away to fix your devices at your
        doorstep. Here are the services we provide:
      </p>

      <div className="service-card">
        <h2 className="service-title">Phone Repair</h2>
        <p className="service-description">
          Cracked screen or battery issues? We'll fix your phone without you
          leaving your home.
        </p>
      </div>

      <div className="service-card">
        <h2 className="service-title">Laptop Repair</h2>
        <p className="service-description">
          Laptop running slow or not turning on? Let us handle your laptop
          repairs without the hassle.
        </p>
      </div>

      <div className="service-card">
        <h2 className="service-title">Smart Speaker Repair</h2>
        <p className="service-description">
          Problems with your smart speaker's audio or connectivity? We'll make
          it sound perfect again.
        </p>
      </div>

      <div className="service-card">
        <h2 className="service-title">Tablet Repair</h2>
        <p className="service-description">
          Whether it's a cracked screen or software issues, we'll get your
          tablet back to its optimal condition.
        </p>
      </div>

      <div className="service-card">
        <h2 className="service-title">Gaming Consoles Repair</h2>
        <p className="service-description">
          Is your game console acting up? We're experts in fixing gaming
          consoles so you can get back to your favorite games.
        </p>
      </div>

      <div className="service-card">
        <h2 className="service-title">iMac Repair</h2>
        <p className="service-description">
          iMac problems? Our technicians are well-versed in iMac repairs, and
          we'll resolve your issues swiftly.
        </p>
      </div>

      <div className="service-card">
        <h2 className="service-title">Smartwatch Repair</h2>
        <p className="service-description">
          From cracked screens to battery replacements, we'll keep your
          smartwatch running smoothly.
        </p>
      </div>

      <div className="service-card">
        <h2 className="service-title">TV Repair</h2>
        <p className="service-description">
          TV not displaying correctly? Our experts will ensure you enjoy
          uninterrupted entertainment.
        </p>
      </div>

      <div className="service-card">
        <h2 className="service-title">Earbuds Repair</h2>
        <p className="service-description">
          Lost audio quality in your earbuds? We'll restore the sound quality
          for your music and calls.
        </p>
      </div>

      <div className="service-card">
        <h2 className="service-title">DSLR Camera Repair</h2>
        <p className="service-description">
          Shutter not working or lens issues? Trust us to fix your DSLR camera
          like new.
        </p>
      </div>

      <div className="service-card">
        <h2 className="service-title">AC and E-Scooter Repair</h2>
        <p className="service-description">
          Is your AC not cooling or e-scooter facing issues? We'll bring back
          the comfort and mobility to your doorstep.
        </p>
      </div>
    </div>
  );
}

export default Services;
