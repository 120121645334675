// Contact.js
import React, { useState , useEffect} from "react";
import "./styles/contact.css";
import whatsappIcon from "./images/whatsapplogo.png";
import { Helmet } from "react-helmet"; // Import Helmet from the react-helmet library

function Contact() {
     // Scroll to the top of the page when the component mounts (or loads) after the redirect
     useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
  });

  // const [formSubmissions, setFormSubmissions] = useState([]); // Define the formSubmissions state
  const [submissionStatus, setSubmissionStatus] = useState(null); // State for submission status

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Send form data to the backend server
    fetch("http://localhost:3001/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Form submission successful:", data);
        setSubmissionStatus("success"); // Set submission status to success
        setFormData({ name: "", email: "", mobile: "", message: "" }); // Clear input fields
      })
      .catch((error) => {
        console.error("Error submitting the form:", error);
        setSubmissionStatus("error"); // Set submission status to error
      });
  };

  return (
    <div className="container">
      <Helmet>
        <title>
          RepairTechies: Welcome to RepairTechies, your one-stop destination for
          top-quality repair services in India. We specialize in repairing
          mobile phones, laptops, tablets, and more, offering fast and reliable
          solutions to get your valuable devices back in working order.
          E-scooter repair services in Delhi.
        </title>
        <meta
          name="description"
          content="Mobile phone repair service, Smartphone screen repair, scooter repair near me ,i Phone repair near me, 
          Android phone repair, Mobile battery replacement, Cell phone water damage repair, 
          Mobile repair shop, Cracked phone screen repair, Mobile device fix, 
          Broken phone repair, Laptop repair services, Laptop screen replacement, 
          Laptop motherboard repair, MacBook repair center, Laptop keyboard repair, 
          Laptop overheating fix, PC laptop repair shop, Laptop hardware repair, 
          Dell laptop repair near me, Laptop data recovery, Tablet screen repair, 
          iPad repair service, Android tablet fix, Tablet battery replacement, 
          Tablet charging port repair, Samsung tablet repair, Broken tablet screen fix, 
          Tablet water damage repair, Tablet technician near me, Tablet software troubleshooting, 
          Electric scooter repair service, E-scooter maintenance, Electric scooter battery replacement, 
          E-scooter brake repair, Scooter tire replacement, Electric scooter motor repair, 
          E-scooter charging issues, Electric scooter diagnostic check, Scooter repair shop, 
          E-scooter technician, Scooter repair near me, Electric scooter repair near me, 
          Scooter repair shop near me, Scooter repair shops, Electric scooter repair shop near me, 
          Scooter repair home service near me, Scooter tire repair near me, How to repair scooter tire, 
          How to repair electric scooter."
        />
      </Helmet>
      <h1>Contact Us</h1>
      <p>
        Mobile: <a href="tel:8542832268">8542832268</a>
      </p>
      <p>
        Whatsapp No:{" "}
        <img
          src={whatsappIcon}
          alt="WhatsApp Icon"
          style={{
            verticalAlign: "middle",
            marginRight: "8px",
            height: "20px",
          }}
        />{" "}
        <a href="https://wa.me/8542832268">8542832268</a>
      </p>
      <p className="contact-email">
        You can Mail us:{" "}
        <a href="mailto:carpentry.contact@gmail.com">
          contact.repairtechies@gmail.com
        </a>
      </p>
      {/* form Integration */}
      {submissionStatus === "success" && (
        <div className="success-message">Form submitted successfully!</div>
      )}
      {submissionStatus === "error" && (
        <div className="error-message">
          Error submitting the form. Please try again.
        </div>
      )}
      <form className="form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="mobile">Mobile:</label>
          <input
            type="tel"
            id="mobile"
            name="mobile"
            value={formData.mobile}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message:</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            rows={4}
            required
          />
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default Contact;
