import React, { useState} from 'react';
import axios from 'axios';

const BlogPage = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [validationResult, setValidationResult] = useState(null);
    const accessKey = '6a5bb98867389a90e60cb6df14d1eeca';
  
    const handleValidation = async () => {
      try {
        const response = await axios.get('http://apilayer.net/api/validate', {
          params: {
            access_key: accessKey,
            number: phoneNumber,
            country_code: countryCode,
            format: 1,
          },
        });
  
        if (response.data.valid) {
          setValidationResult('Phone number is valid');
        } else {
          setValidationResult('Phone number is not valid');
        }
      } catch (error) {
        console.error('Error validating phone number:', error);
        setValidationResult('Error occurred while validating the phone number');
      }
    };
  
    return (
      <div>
        
        <h1>Validate Phone Number</h1>
        
        <input
          type="text"
          placeholder="Phone Number"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
        <input
          type="text"
          placeholder="Country Code"
          value={countryCode}
          onChange={(e) => setCountryCode(e.target.value)}
        />
        <button onClick={handleValidation}>Validate</button>
        {validationResult && <p>{validationResult}</p>}
      </div>
    );
  };
  

export default BlogPage;
