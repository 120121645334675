import { React, useEffect } from "react";
import "./styles/MobileRepair.css"; // Import your CSS file for styling
import whatsappIcon from "./images/whatsapplogo.jpg";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet"; // Import Helmet from the react-helmet library

// import back from "./images/back.jpg";
function MobileRepair() {
  // Scroll to the top of the page when the component mounts (or loads) after the redirect
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="mobile-repair-container">
      <Helmet>
        <title>
          RepairTechies:Welcome to RepairTechies, your one-stop destination for
          top-quality repair services in India. We specialize in repairing
          mobile phones, laptops, tablets, and more, offering fast and reliable
          solutions to get your valuable devices back in working order Mobile
          repair service in India Laptop repair in Uttar Pradesh India.
        </title>
        <meta
          name="description"
          content="Mobile phone repair service, Smartphone screen repair, iPhone repair near me,
             Android phone repair, Mobile battery replacement, Cell phone water damage repair, 
             Mobile repair shop, Cracked phone screen repair, Mobile device fix, Broken phone repair,
              Laptop repair services, E-scooter brake repair, Scooter tire replacement,
                   Electric scooter motor repair, E-scooter charging issues, Electric scooter diagnostic check,
                    Scooter repair shop, E-scooter technician."
        />
      </Helmet>
      {/* Hero Section */}
      <section className="hero">
        <div className="hero-content">
          <h1 className="hero-title">Your Trusted Mobile Repair Experts</h1>
          <p className="hero-description">
            Get your smartphone back in action with our top-notch repair
            services. Our skilled technicians are here to rescue your device.
          </p>
          <button className="hero-button">Learn More</button>
        </div>
        {/* Add your hero image here */}
        {/* <div className="hero-image">
          
          <img src={back} alt="Mobile Repair Services" />
        </div> */}
      </section>
      {/* Contact Section */}
      <div className="contact-container">
        <section className="contact">
          <h1>Contact Us</h1>
          <p>
            Mobile: <a href="tel:8542832268">8542832268</a>
          </p>
          <p>
            Whatsapp No:{" "}
            <img
              src={whatsappIcon}
              alt="WhatsApp Icon"
              style={{
                verticalAlign: "middle",
                marginRight: "8px",
                height: "20px",
              }}
            />{" "}
            <a href="https://wa.me/8542832268">8542832268</a>
          </p>
          <p className="contact-email">
            You can Mail us:{" "}
            <a href="mailto:contact.repairtechies@gmail.com">
              contact.repairtechies@gmail.com
            </a>
          </p>
        </section>
      </div>

      {/* Services Section */}
      <section className="services">
        <div className="service">
          <div className="service-icon">
            <i className="fas fa-tools"></i>
          </div>
          <h2 className="service-title">Expert Technicians</h2>
          <p className="service-description">
            Our expert technicians are trained to handle various mobile phone
            issues.
          </p>
        </div>

        <div className="service">
          <div className="service-icon">
            <i className="fas fa-clock"></i>
          </div>
          <h2 className="service-title">Quick Turnaround</h2>
          <p className="service-description">
            We strive to provide quick and efficient repair services to minimize
            your downtime.
          </p>
        </div>

        <div className="service">
          <div className="service-icon">
            <i className="fas fa-shield-alt"></i>
          </div>
          <h2 className="service-title">Quality Repairs</h2>
          <p className="service-description">
            We use high-quality parts to ensure the durability of our repairs.
          </p>
        </div>

        {/* Additional Services */}
        <div className="service">
          <div className="service-icon">
            <i className="fas fa-battery-full"></i>
          </div>
          <h2 className="service-title">Battery Replacement</h2>
          <p className="service-description">
            Is your phone's battery life declining? We offer battery replacement
            services to improve your phone's performance.
          </p>
        </div>

        <div className="service">
          <div className="service-icon">
            <i className="fas fa-wrench"></i>
          </div>
          <h2 className="service-title">Water Damage Repair</h2>
          <p className="service-description">
            Accidentally got your phone wet? Our technicians can attempt to
            repair water-damaged phones and restore them to working condition.
          </p>
        </div>

        <div className="service">
          <div className="service-icon">
            <i className="fas fa-camera"></i>
          </div>
          <h2 className="service-title">Camera Repair</h2>
          <p className="service-description">
            Experiencing camera issues? We can repair or replace camera modules
            to get your photography back on track.
          </p>
        </div>

        {/* Add more services as needed */}
      </section>

      {/* Call to Action */}
      <section className="cta">
        <h2 className="cta-title">Ready to Get Your Phone Repaired?</h2>
        <p className="cta-description">
          Contact us today to schedule your mobile phone repair service.
        </p>{" "}
        <Link href="/Contact" className="cta-button">
          Contact Us
        </Link>
      </section>
    </div>
  );
}

export default MobileRepair;
