// App.js
import React from "react";
import { Route, Routes } from "react-router-dom";

import Home from "./components/Home";
import Header from "./components/Header";
import About from "./components/About";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Services from "./components/Services";
import MobileRepair from "./components/MobileRepair";
import LaptopRepair from "./components/LaptopRepair";
import SellBuy from "./components/SellBuy";
import TechBlog from "./components/TechBlog";
// import HindiBlog from "./components/HindiBlog";
import Page404 from "./components/Page404";
import BlogPage from "./components/BlogPage";
// import RomanticTalk from "./components/RomanticTalk";
// import PickupLines from "./components/PickupLines";
import Evehicle from "./components/Evehicle";

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/india-repairtechies-contact" element={<Contact />} />
        <Route path="/india-repairtechies-about" element={<About />} />
        {/* <Route path="/india-repairtechies-services" element={<Services />} /> */}
        <Route path="/online-mobile-repair" element={<MobileRepair />} />
        <Route path="/online-laptop-repair" element={<LaptopRepair />} />
        <Route path="/online-tech-services" element={<Services />} />
        <Route path="/online-sell-buy" element={<SellBuy />} />
        <Route path="/best-tech-blog-news" element={<TechBlog />} />
        {/* <Route path="/best-sports-tech-blog-news" element={<HindiBlog />} /> */}
        <Route path="/latest-tech-blog-news" element={<BlogPage />} />
        <Route path="/online-escooter-repair" element={<Evehicle />} />
        {/* <Route path="/online-love-pick-up-lines" element={<PickupLines />} /> */}

        {/* <Route path="/online-love-pick-up-lines" element={<PickupLines />} /> */}

        <Route path="*" element={<Page404 />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
